@font-face {
    font-family: 'Baloo';
    src: url('./fonts/Baloo-Regular.ttf');
}


body {
    font-family: 'Baloo';
    background-color: 'white';
    
 
   
}

head {
    font-family: 'Baloo';
    background-color: 'white';
   
}

div {
    font-family: 'Baloo';
    background-color: 'white';
  
}
html, body {
  background-color: 'white';
  height: 100%;
  margin: 0;
  padding: 0;
} 

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 50px;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

video {
  -webkit-animation: alternate;
  -webkit-mask: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}





